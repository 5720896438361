<template>
    <div class="order-detail">
        <div class="title-wrap">
          <h3 @click="goOrders" class="title" :style="{borderColor: $store.state.themeColor}">订单列表</h3><span>/</span><h3>订单详情</h3>
        </div>

        <div class="course" v-for="(item, index) in order.items" :key="index">
            <div class="top">
                <span style="flex:1;font-weight: bold;">课程信息</span>
                <span style="flex:1;font-weight: bold;">实付金额</span>
            </div>
            <div class="product">
                <div class="p-l">
                    <span v-if="item.course.is_show == 2" class="status-icon status-delete-icon">已删除</span>
                    <span v-if="item.course.is_show == 0" class="status-icon status-shelves-down-icon">已下架</span>
                    <img :src="item.course.picture" alt="">
                    <div class="pro-tit-wrap">
                        <p class="pro-tit">{{item.course.title}}</p>
                        <p>{{item.course.period}}学时</p>
                    </div>
                </div>
                <div class="price"><span style="font-size: 14px;">{{order.pay_amount>0? '￥': ''}}</span>{{order.pay_amount>0? order.pay_amount: '免费'}}</div>
            </div>
        </div>

        <div class="item">
          <div class="">
            <div class="">
              <span style="font-weight: bold;">订单信息</span>
              <span></span>
            </div>
            <div class="">
              <span>订单状态：</span>
              <span>{{orderStatus(order.status)}}</span>
            </div>
            <div class="">
              <span>订单编号：</span>
              <span>{{order.sn}}</span>
            </div>
            
            <div class="">
              <span>下单时间：</span>
              <span>{{order.created_time}}</span>
            </div>
          </div>
          <div class="">
            <div class="" style="justify-content: space-between;padding-right: 20px;">
              <span style="font-weight: bold;">支付信息</span>
              <span v-if="order.refund_status == 1" @click="refundDoing(order.status, order.sn)" style="color:#0071F5;cursor: pointer;">申请退款</span>
              <span v-else-if="order.refund_status == 3" @click="refundDoing(order.status, order.sn)" style="color:#0071F5;cursor: pointer;">退款明细</span>
              <span v-else></span>
            </div>
            <div class="">
              <span>支付状态：</span>
              <span>{{orderStatus(order.status)}}</span>
            </div>
            <div class="">
              <span>支付方式：</span>
              <span>{{payMentTxt(order.payment)}}</span>
            </div>
            <div class="">
              <span>支付时间：</span>
              <span>{{order.pay_time}}</span>
            </div>
          </div>
        </div>

        <!-- <div class="item" style="height:228px;" v-if="order.pay_amount > 0"> -->
        <div class="item" style="height:228px;" v-if="order.pay_amount > 0 && $store.state.open_wechat_pay==0">
          <div class="">
            <div class="" style="justify-content: space-between;padding-right: 20px;">
              <span style="font-weight: bold;">开票信息</span>
              <span v-if="order.invoice_status == 1" @click="takeInvoice" style="color:#0071F5;cursor: pointer;">开发票</span>
              <span v-if="order.invoice_status == 3" @click="takeInvoice" style="color:#0071F5;cursor: pointer;">查看发票</span>
            </div>
            <div class="">
              <span>开票状态：</span>
              <div style="margin-left: 20px;">
                <span v-if="!invoice">--</span>
                <span v-else-if="invoice && invoice.status == 'sent'">已开票</span>
                <span v-else-if="invoice && invoice.status == 'doing'">开票中</span>
                <span v-else style="color: red;">开票失败</span>
              </div>
              
            </div>
            <div class="">
              <span>发票类型：</span>
              <span>电子普通发票</span>
              <!-- <span>{{invoice && invoice.title_type == 'person'? '个人': invoice && invoice.title_type == 'enterprise'? '企业': '--'}}</span> -->
            </div>
            <div class="">
              <span>发票抬头：</span>
              <span>{{invoice && invoice.title? invoice && invoice.title: '--'}}</span>
            </div>
            <div class="">
              <span>发票内容：</span>
              <span>{{invoice && invoice.content? invoice && invoice.content: '--'}}</span>
            </div>
            <div class="">
              <span>开票时间：</span>
              <span>{{invoice && invoice.created_time? invoice && invoice.created_time: '--'}}</span>
            </div>
          </div>
          <div class="">

          </div>
        </div>

        <a-modal width='500px' :zIndex="1000" v-model="visible" footer="" title="申请退款">
            <div class="info">
                <!-- <div><span class="red">*</span>需同时满足以下条件时才可申请退款</div> -->
                <div class="tag">
                    <!-- <img v-if="is_learn == 0" src="../../assets/images/refundIcon.png" alt="">
                    <img v-if="is_learn == 1" src="../../assets/images/xstatus.png" alt=""> -->
                    <img style="margin-right: 10px;" src="../../assets/images/warn.png" alt="">
                    <p>退款需满足课程内任何一个课时没有学习进度，才可以申请退款。</p>
                </div>
                <!-- <div class="tag">
                    <img v-if="is_invoice == 0" src="../../assets/images/refundIcon.png" alt="">
                    <p>该订单没有开具过发票</p>
                </div> -->
            </div>
            <div class="txt-info" v-if="order.status == 'paid' || order.status == 'finished'">
                <div>
                    <label for=""><span class="red">*</span>退款原因</label>
                    <a-select default-value="1" style="width: 120px;" @change="handleChange">
                        <a-select-option value="1">买错课了</a-select-option>
                        <a-select-option value="2">不想学了</a-select-option>
                        <a-select-option value="3">其他</a-select-option>
                    </a-select>
                </div>
                <div>
                    <label for="">退款备注</label>
                    <a-textarea v-model="msg" placeholder="请输入..." :rows="4" />
                </div>
            </div>
            <div class="refundTxt">
                <div class="">
                  <span>退款规则：</span>
                  <span>按照原路返回支付账户</span>
                </div>
                <div class="">
                  <span>退款时间：</span>
                  <span>一般为3个工作日左右</span>
                </div>
            </div>

            <div class="">
              <!-- <p v-if="order.status == 'refunded' || order.status == 'refunding'">开票信息</p> -->
              <p v-if="(order.status == 'refunded' || order.status == 'refunding') && $store.state.open_wechat_pay==0">开票信息</p>
              <a-timeline>
                <a-timeline-item v-for="(item, index) in refund_flow" :key="index">
                  <p v-for="(items, i) in item.text" :key="i">{{items.value}}</p>
                  <p>{{item.deal_time}}</p>
                </a-timeline-item>
              </a-timeline>
            </div>
            <div class="refund-btn">
                <a-button v-if="order.status == 'paid' || order.status == 'finished'" @click="refundding(order.sn)" type="primary">申请退款</a-button>
                <a-button v-if="order.status == 'refunded' || order.status == 'refunding'" @click="ok(order.sn)" type="primary">确定</a-button>
            </div>

        </a-modal>

        <!-- <a-modal width='500px' v-model="visible2" footer="" :title="!invoice || invoice && invoice.status == 'refused'? '开发票': '查看发票'"> -->
        <a-modal v-if="$store.state.open_wechat_pay==0" width='500px' v-model="visible2" footer="" :title="!invoice || invoice && invoice.status == 'refused'? '开发票': '查看发票'">
          <div class="modal-top" v-if="!invoice || invoice && invoice.status == 'refused'">
            <div class="input-item">
              <div class="">
                <label for="">发票类型</label>
                <a-radio-group>
                  <a-radio>
                    电子普通发票
                  </a-radio>
                </a-radio-group>
              </div>

            </div>
            <div class="input-item">
              <div class="">
                <label for="">发票抬头</label>
                <a-radio-group v-model="value" @change="onChange">
                  <a-radio :value="0">
                    个人
                  </a-radio>
                  <a-radio :value="1">
                    单位
                  </a-radio>
                </a-radio-group>
              </div>

            </div>
            
            <div class="input-item" v-if="value == 0">
              <div class="">
                <label for="">发票内容</label>
                <a-input v-model="buyer_name" placeholder="请输入个人或您的姓名" />
              </div>

            </div>

            <div class="input-item" v-if="value == 1">
              <div class="">
                <label for="">企业名称</label>
                <a-input v-model="buyer_name" placeholder="请输入企业名称" />
              </div>
              <div class="">
                <label for="">企业税号</label>
                <a-input v-model="tax_num" placeholder="请输入企业税号" />
              </div>

            </div>
            
            <div class="input-item">
              <div class="">
                <label for="">手机号</label>
                <a-input v-model="mobile" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="input-item">
              <div class="">
                <label for="">收票邮箱</label>
                <a-input v-model="email" placeholder="请输入接收发票的邮箱" />
              </div>

            </div>
          </div>
          <div class="modal-bot" v-if="!invoice || invoice && invoice.status == 'refused'">
            <p class="invoice-title">开具发票规则</p>
            <div class="content">
              <p>1. 根据国家税务规定，不能向个人开具增值税专用发票</p>
              <p>2. 发票金额不含优惠券支付部分</p>
              <p>3. 不同开票主体的发票，需要分别提交开票申请</p>
              <p>4. 电子普通发票是税局认可的有效付款凭证，其法律效力、基本用途及使用规定同纸质发票</p>
              <p>5. 请您按照税法规定使用发票</p>
              <p>6. 有任何疑问，请您及时咨询我们的客服</p>
            </div>
          </div>

          <div class="invoice-btn" v-if="!invoice || invoice && invoice.status == 'refused'">
            <a-button @click="startInvoice" type="primary">立即开票</a-button>
          </div>

          <div class="see-invoice" v-if="invoice && invoice && invoice.status == 'doing' || invoice && invoice.status == 'sent'">
            <div class="">
              <label for="">发票类型</label>
              <p>电子普通发票</p>
            </div>
            <div class="">
              <label for="">发票抬头</label>
              <p>{{invoice.title}}</p>
            </div>
            <div class="">
              <label for="">收票邮箱</label>
              <p>{{invoice.email}}</p>
            </div>
          </div>

          <div class="invoice-img" v-if="invoice && invoice.status == 'doing' || invoice && invoice.status == 'sent'">
            <div class="invoice-img-title">
              <span style="font-weight: bold;">发票</span>
              <a :href="invoice.img" target="_blank" download style="color:#0071F5;cursor:pointer;">下载发票</a>
            </div>
            <img :src="invoice.img" alt="">
          </div>
          <div class="invoice-btn">
            <a-button v-if="invoice && invoice.status == 'doing' || invoice && invoice.status == 'sent'" @click="invoiceOk" type="primary">确定</a-button>
          </div>
        </a-modal>
    </div>
</template>
<script>
import {
  orderDetail,
  checkOrder,
  orderRefund,
  refundInfo,
  issue
} from '../../http/api'
export default {
    data() {
        return {
            visible: false,
            order: '',
            is_learn: '',
            is_invoice: '',
            msgType: '1',
            msg: '',
            refund: '',
            visible2: false,
            value: 0,
            mobile: '',
            email: '',
            buyer_name: '',
            tax_num: '',
            invoice: '',
            invoiceType: 0,
            msgFlag: true,
            refund_flow: ''
        }
    },
    mounted() {
      this.update()
    },
    methods: {
      payMentTxt(param) {
        if(param == 'wechat' || param == 'mini_wechat') {
          return '微信'
        } else if(param == 'adapay') {
          return '支付宝'
        } else {
          return '免费'
        }
      },
      goOrders() {
        this.$router.push('/user/orders')
      },
      update() {
        orderDetail({
          order_sn: this.$route.query.sn
        }).then(res => {
          if(res.data.code == 200) {
            this.order = res.data.data.order
            this.invoice = res.data.data.invoice
            this.refund_flow = res.data.data.order.refund_flow
          }
        })
      },
      startInvoice() {
        let regex = /^[a-zA-Z\u4e00-\u9fa5]{1,20}$/
        // regex = /^([a-zA-Z ]+|[\u4e00-\u9fa5]+)$/
        // console.info(regex.test(this.buyer_name))
        if(this.invoiceType == 0) {
          if(!this.buyer_name) {
            if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("请输入发票内容", () => {
                this.msgFlag = true
              })
            }
            return false
          } else if(!regex.test(this.buyer_name)) {
            if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("发票内容请输入中文或英文", () => {
                this.msgFlag = true
              })
            }
            return false
          }
        }
        if(this.invoiceType == 1) {
          if(!this.buyer_name) {
            if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("请输入企业名称", () => {
                this.msgFlag = true
              })
            }
            return false
          } else if(!regex.test(this.buyer_name)) {
            if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("企业名称请输入中文或英文", () => {
                this.msgFlag = true
              })
            }
            return false
          }
          if(!this.tax_num) {
            if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("请输入企业税号", () => {
                this.msgFlag = true
              })
            }
            return false
          }
        }
        if(!this.mobile) {
          if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("请输入您的手机号", () => {
                this.msgFlag = true
              })
            }
          return false
        }
        if(!this.email) {
          if(this.msgFlag) {
              this.msgFlag = false
              this.$message.error("请输入您的收票邮箱", () => {
                this.msgFlag = true
              })
            }
          return false
        }
        issue({
          order_sn: this.order.sn,
          mobile: this.mobile,
          email: this.email,
          invoice_type: this.value,
          buyer_name: this.buyer_name,
          tax_num: this.tax_num
        }).then(res => {
          if(res.data.code == 200) {
            this.visible2 = false
            this.update()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      onChange(e) {
        this.invoiceType = e.target.value
        this.buyer_name = ''
        this.tax_num = ''
        this.mobile = ''
        this.email = ''
      },
      takeInvoice() {
        if(this.invoice && this.invoice.status == 'doing') {
          if(this.msgFlag) {
            this.msgFlag = false
            this.$message.error("正在开票中，请稍后！", () => {
              this.msgFlag = true
            })
          }
          
        } else {
          this.buyer_name = ''
          this.tax_num = ''
          this.mobile = ''
          this.email = ''
          this.visible2 = true
        }
        
      },
      handleChange(val) {
        this.msgType = val
      },
      refundDoing(status, sn) {
        if(status == 'paid' || status == 'finished') {
          if(this.order.learned){
            return this.$message.warning('退款失败，课程已学习');
          }
          this.visible = true
          checkOrder({
            order_sn: sn
          }).then(res => {
            if(res.data.code == 200) {
              this.is_learn = res.data.data.is_learn
              this.is_invoice = res.data.data.is_invoice
            }
          })
        } else if(status == 'refunded' || status == 'refunding') {
          refundInfo({
            order_sn: sn
          }).then(res => {
            if(res.data.code == 200) {
              this.is_learn = res.data.data.check_refund.is_learn
              this.is_invoice = res.data.data.check_refund.is_invoice
              this.refund = res.data.data.refund
              this.refund_flow = res.data.data.refund.refund_flow
              this.visible = true
            }
          })
        }
      },
      ok() {
        this.visible = false
      },
      invoiceOk() {
        this.visible2 = false
      },
      refundding(sn) {
        // if(!this.msg) {
        //   if(this.msgFlag) {
        //     this.msgFlag = false
        //     this.$message.error("请填写备注", () => {
        //       this.msgFlag = true
        //     })
        //   }
        //   return false
        // }
        
        orderRefund({
          order_sn: sn,
          reason: this.msgType,
          remark: this.msg
        }).then(res => {
          if(res.data.code == 200) {
            this.visible = false
            this.$message.success(res.data.message)
            this.update()
          } else {
            this.$message.warning(res.data.message);
            this.visible=false;
              
            if(res.data.message=='退款失败，课程已学习'){
                this.$set(this.order,'learned',1)
            }

          } 
        })
      },
      orderStatus(key) {
        if(key == 'closed') {
          return '已取消'
        } else if(key == 'created') {
          return '待支付'
        } else if(key == 'paid') {
          return '已支付'
        } else if(key == 'refunded') {
          return '已退款'
        } else if(key == 'finished') {
          return '已完成'
        } else if(key == 'refunding') {
          return '退款中'
        }
      },
      back() {
          this.$router.push({
              path: '/user/orders'
          })
      }
    }
}
</script>

<style scoped>
    .title-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 44px;
      padding: 0 15px;
      border: 1px solid #EBEFF2;
      margin-bottom: 20px;
    }
    .title-wrap h3 {
      margin: 0!important;
      font-size: 16px;
      font-weight: bold;
    }
     .title-wrap span {
       color: #999;
       padding:0 5px;
       font-size: 16px;
     }
    .title {
      color: #999;
        border-left: 4px solid #fff;
        text-align: left;
        padding-left: 10px;
        font-weight: bold;
        line-height: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .top {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }
    .top span {
        flex: 1;
        text-align: left;
        padding-left: 20px;
        box-sizing: border-box;
    }
    .product {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-bottom: 15px;
    }
    .p-l {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        text-align: left;
        border-right: 1px solid #EBEFF2;
        height: 84px;
        position: relative;
    }
    .p-l p {
      margin-bottom: 0;
      font-size: 12px;
    }
    .pro-tit-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .pro-tit {
      padding-right: 60px;
    }
    .p-l img {
      width: 142px;
        min-width: 142px;
        height: 84px;
        border: 1px solid #eee;
        border-radius: 6px;
        margin-right: 20px;
        object-fit: cover;
    }
    .course {
        border: 1px solid #eee;
        margin-bottom: 20px;
        border-top: 2px solid #3091FD;
    }
    .price {
        flex: 1;
        color: #FC583D;
        font-size: 28px;
        text-align: left;
        padding-left: 20px;
    }
    .ant-tag {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
    }
    .tag {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .tag img {
      width: 22px;
      height: 22px;
    }
    .tag p {
      margin-bottom: 0;
    }
    .info {
        border-bottom: 1px solid #EFEFEF;
        padding: 20px 0;
        border-radius: 4px;
        margin-bottom: 20px;
        background: #eee;
    }
    .info>div {
      margin-bottom: 10px;
    }
    .item {
      border: 1px solid #EBEFF2;
      border-top: 2px solid #C2CED3;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      padding: 10px 0;
    }
    .item>div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding-left: 20px;
      height: 100%;
    }
    .item>div:first-child {
      border-right: 1px solid #EBEFF2;
    }
    .item>div>div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      padding: 8px 0;
      width: 100%;
    }
    .item>div>div>span:last-child {
      color: #666;
      margin-left: 20px;
    }
    .txt-info {
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 20px;
    }
    .txt-info>div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
    }
    .txt-info>div>label {
      margin-right: 10px;
      min-width: 70px;
    }
    .refundTxt {
      margin-bottom: 60px;
    }
    .refundTxt>div {
      margin-bottom: 20px;
    }
    .refundTxt>div>span:first-child {
      color: #333;
    }
    .refundTxt>div>span:last-child {
      color: #666;
    }
    .refund-btn {
      text-align: center;
    }

    .modal-top {
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 20px;
    }
    .modal-top .input-item {
      margin-bottom: 20px;
    }
    .modal-top .input-item>div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }
    .modal-top .input-item>div>label {
      min-width: 50px;
      font-size: 12px;
      margin-right: 20px;
      font-weight: bold;
    }
    .modal-bot {
      margin-bottom: 30px;
    }
    .modal-bot .invoice-title{
      font-size: 12px;
      font-weight: bold;
    }
    .modal-bot .content {
      height: 220px;
      overflow-y: auto;
    }
    .modal-bot .content::-webkit-scrollbar, .player-list::-webkit-scrollbar {
      width:3px;
    }
    .modal-bot .content::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
        background-color:#aaa;
    }
    .invoice-btn {
      text-align: center;
    }
    .see-invoice {
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 20px;
    }
    .see-invoice>div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }
    .see-invoice>div>label {
      font-weight: bold;
    }
    .see-invoice>div>p {
      margin: 0 0 0 20px;
    }
    .invoice-img {
      margin-bottom: 30px;
    }
    .invoice-img-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .invoice-img img {
      width: 100%;
      height: 298px;
    }
</style>
