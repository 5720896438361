<template>
    <div class="cert">
        <img :src="url" alt="">
    </div>
    
</template>

<script>
import {
    getCert
} from '../../http/api'
export default {
    data() {
        return {
            url: ''
        }
    },
    mounted() {
        getCert({
            sn: this.$route.query.sn
        }).then(res => {
            if(res.data.code == 200) {
                this.url = res.data.data.oss_cert_photo
            }
        })
    }
}
</script>

<style scoped>
    .cert {
        width: 100%;
        margin: 20px auto;
    }
</style>