<template>
    <div class="descript">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>班级介绍</h3>
        </div>
        <div class="rich-text-wrap"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            html: ''
        }
    },
    props: {
        classes: {}
    },
    watch: {
        'classes'(n, o) {
            this.html = this.classes.description? this.classes.description: ''
            document.querySelector('.rich-text-wrap').innerHTML = this.html
        }
    },
    mounted() {
        this.html = this.classes.description? this.classes.description: ''
        document.querySelector('.rich-text-wrap').innerHTML = this.html
    }
}
</script>

<style scoped>
.info {
    color: #666;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
    line-height: 22px;
}

</style>