var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descript" }, [
      _c("div", { staticClass: "title-class-all" }, [
        _c("span", { staticClass: "icon" }),
        _c("h3", [_vm._v("班级介绍")]),
      ]),
      _c("div", { staticClass: "rich-text-wrap" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }