<template>
  <div class="help">
    <div class="wrap">
     <List v-if="$store.state.template == 1" :type="'other'" :cate="2"></List>
     <ListTwo v-if="$store.state.template == 2" :type="'other'" :cate="2"></ListTwo>
     <ListThr v-if="$store.state.template == 3" :type="'other'" :cate="cate"></ListThr>
    </div>
  </div>
</template>

<script>
import List from '@/components/list/index.vue'
import ListTwo from '@/components/list/two.vue'
import ListThr from '@/components/list/thr.vue'
export default {
    data() {
        return {
            cate: ''
        }
    },
    components: {
      List,
      ListTwo,
      ListThr
    },
    created() {
        this.cate = this.$route.query.id
    }
}
</script>

<style scoped>
.wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>