export default {
    menu: [
        {
            id: 1,
            name: '焊工'
        },
        {
            id: 2,
            name: 'web开发'
        },
        {
            id: 3,
            name: '厨师'
        },
        {
            id: 4,
            name: '装台工'
        },
        {
            id: -1,
            name: '查看更多'
        },
    ],
    data: [
        {
          id: 1,
          data: [
            {
                "company": "京东集团",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/33e052361693f8371nF-3d25.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/81adbb518a03e7261nd62N67ElJY.html",
                "title": "人工智能产品经理",
                "jobarea": "北京·大兴区·亦庄",
                "red": "30-60K·14薪",
                "joblimit": "5-10年本科",
                "info": "张先生招聘者",
                "scale": "电子商务已上市10000人以上",
                "falselink_link": "https:\/\/www.zhipin.com\/i100001\/",
                "falselink": "电子商务",
                "keywords": "TO B\n                                                    人工智能\n                                                    大数据\n                                                    供应链\n                                                    电子商务",
                "tags": "TO B",
                "description": "定期体检，节日福利，员工旅游，股票期权，补充医疗保险，带薪年假，免费班车，交通补助，包吃，年终奖，餐补，五险一金，零食下午茶"
            }, {
                "company": "格灵深瞳",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/e133cb4e607d0b7b1nd83N25FFI~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/9172c3702365937a1nRy3dW_GFFW.html",
                "title": "测试\/测开工程师（人工智能\/后端\/服务端）",
                "jobarea": "北京·海淀区·东小口",
                "red": "10-15K·14薪",
                "joblimit": "在校\/应届本科",
                "info": "赵先生招聘专家",
                "scale": "计算机软件D轮及以上100-499人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                "falselink": "计算机软件",
                "keywords": "测试\n                                                    测试开发\n                                                    测试工程师\n                                                    自动化测试\n                                                    软件测试",
                "tags": "测试",
                "description": "定期体检，餐补，五险一金，交通补助，年终奖，股票期权，带薪年假，补充医疗保险"
            }, {
                "company": "建信金科",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/6e19637143bd80ad1HV_3N26GQ~~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/ef13e17636c6d14d3nR72tW7GVU~.html",
                "title": "人工智能专家",
                "jobarea": "北京·西城区·广安门",
                "red": "35-60K",
                "joblimit": "3-5年硕士",
                "info": "林先生主管",
                "scale": "银行不需要融资1000-9999人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100201\/",
                "falselink": "银行",
                "keywords": "数据挖掘\n                                                    数据分析\n                                                    深度学习算法\n                                                    自然语言处理\n                                                    推荐算法",
                "tags": "数据挖掘",
                "description": "餐补，节日福利，工会福利，员工关怀，定期体检，年终奖，带薪年假，五险一金，零食下午茶，补充医疗保险"
            }, {
                "company": "百度",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/ab9fdc6f043679990HY~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/57a6d59dcc3eb9721nZ73d67F1BV.html",
                "title": "行业解决方案专家（人工智能方向）",
                "jobarea": "北京·海淀区·西北旺",
                "red": "50-75K·15薪",
                "joblimit": "5-10年硕士",
                "info": "苏先生产品经理",
                "scale": "互联网已上市10000人以上",
                "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                "falselink": "互联网",
                "keywords": "人工智能\n                                                    SaaS\n                                                    大数据",
                "tags": "人工智能",
                "description": "老板Nice，节日福利，团队氛围好，通讯补贴，加班补助，员工旅游，零食下午茶，交通补助，五险一金，带薪年假，免费班车，定期体检，年终奖，餐补，股票期权，补充医疗保险，住房补贴"
            }, {
                "company": "仙豆智能",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/bcd650d0aa053bf703B73d2-EQ~~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/f07b085761cf60c81nV809u0E1ZY.html",
                "title": "开发测试工程师-人工智能",
                "jobarea": "北京",
                "red": "18-35K·13薪",
                "joblimit": "经验不限学历不限",
                "info": "张女士hr",
                "scale": "互联网不需要融资500-999人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                "falselink": "互联网",
                "keywords": "自动化测试\n                                                    软件测试\n                                                    接口测试\n                                                    人工智能",
                "tags": "自动化测试",
                "description": "餐补，补充医疗保险，零食下午茶，加班补助，带薪年假，定期体检，员工旅游，交通补助，节日福利，五险一金，股票期权，不打卡，年终奖"
            }, {
                "company": "沃东天骏信息技术",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/e4fb3795eccaa5161XF50tS-Eg~~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/5cc317fb7f0987301nV52Nq9GFRZ.html",
                "title": "人工智能产品经理",
                "jobarea": "北京·大兴区·亦庄",
                "red": "25-50K·14薪",
                "joblimit": "5-10年本科",
                "info": "杜先生招聘者",
                "scale": "电子商务已上市10000人以上",
                "falselink_link": "https:\/\/www.zhipin.com\/i100001\/",
                "falselink": "电子商务",
                "keywords": "产品经理\n                                                    人工智能产品",
                "tags": "产品经理",
                "description": "餐补，年终奖，节日福利，免费班车，员工旅游，定期体检，加班补助，补充医疗保险，带薪年假"
            }, {
                "company": "Testin 云测",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/ceb246cf881c752f33d939g~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/5ac2951b6d1e56b31HR609W-EVA~.html",
                "title": "人工智能算法工程师",
                "jobarea": "北京·朝阳区·酒仙桥",
                "red": "20-40K",
                "joblimit": "1年以内学历不限",
                "info": "陈先生招聘者",
                "scale": "互联网D轮及以上500-999人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                "falselink": "互联网",
                "keywords": "Tensorflow\n                                                    自然语言处理\n                                                    图像识别",
                "tags": "Tensorflow",
                "description": "年终奖，定期体检，五险一金，带薪年假，通讯补贴，节日福利，餐补"
            }, {
                "company": "博奥",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/4ae5b37d457e8ee703Vz3ty_Fg~~.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/a3f6670b5c2739681nV_0t24GVtR.html",
                "title": "人工智能实习生",
                "jobarea": "北京·昌平区·回龙观",
                "red": "100-200元\/天",
                "joblimit": "5天\/周6个月硕士",
                "info": "荣女士招聘",
                "scale": "医疗设备\/器械不需要融资1000-9999人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100403\/",
                "falselink": "医疗设备\/器械",
                "keywords": "视觉图像算法\n                                                    深度学习算法\n                                                    自然语言处理",
                "tags": "视觉图像算法",
                "description": ""
            }, {
                "company": "百悟科技",
                "company_link": "https:\/\/www.zhipin.com\/gongsi\/f82a24e2f057072b1nd_3d20.html",
                "title_link": "https:\/\/www.zhipin.com\/job_detail\/bdac88b8cca84e7b1nd_3t25ElY~.html",
                "title": "人工智能算法工程师",
                "jobarea": "北京·朝阳区·望京",
                "red": "20-40K",
                "joblimit": "1-3年硕士",
                "info": "常女士HR",
                "scale": "移动互联网不需要融资500-999人",
                "falselink_link": "https:\/\/www.zhipin.com\/i100019\/",
                "falselink": "移动互联网",
                "keywords": "Linux\n                                                    Java\n                                                    C\/C++",
                "tags": "Linux",
                "description": "交通补助，餐补，带薪年假，通讯补贴，五险一金"
            }
          ]  
        },
        {
            id: 2,
            data: [
                {
                    "company": "国电南瑞科技",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/d763379ea54fe34a1XN82tu4GA~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/8619709783d8740303J80tq9E1A~.html",
                    "title": "人工智能研发工程师（知识服务方向）",
                    "jobarea": "北京·海淀区·上地",
                    "red": "18-26K·13薪",
                    "joblimit": "3-5年硕士",
                    "info": "刘先生招聘经理",
                    "scale": "电力\/热力\/燃气\/水利不需要融资500-999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i101205\/",
                    "falselink": "电力\/热力\/燃气\/水利",
                    "keywords": "人工智能\n                                                    知识图谱\n                                                    自然语言处理",
                    "tags": "人工智能",
                    "description": "企业年金，餐补，五险一金，员工旅游，包吃，零食下午茶，股票期权"
                }, {
                    "company": "第四范式",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/3c8cfc963370953603V42N4~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/e2eceaf2862a461003dy29i1EFs~.html",
                    "title": "人工智能服务平台产品经理",
                    "jobarea": "北京·海淀区·上地",
                    "red": "20-30K·14薪",
                    "joblimit": "1-3年本科",
                    "info": "张女士HR",
                    "scale": "计算机软件D轮及以上500-999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "机器学习社区",
                    "tags": "机器学习社区",
                    "description": "加班补助，带薪年假，通讯补贴，补充医疗保险，员工旅游，节日福利，年终奖，餐补，五险一金，定期体检，交通补助，零食下午茶"
                }, {
                    "company": "卓朗科技",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/396976bbc8007a8933J70tW_.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/c657c4e7a2f53d1e3nR729-1GVc~.html",
                    "title": "人工智能产品经理",
                    "jobarea": "北京·朝阳区·三元桥",
                    "red": "20-30K",
                    "joblimit": "3-5年本科",
                    "info": "李先生HR招聘",
                    "scale": "计算机软件已上市500-999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "NLP\n                                                    人工智能产品\n                                                    机器学习\n                                                    人工智能\n                                                    大数据",
                    "tags": "NLP",
                    "description": "年终奖，零食下午茶，餐补，带薪年假，五险一金，节日福利，员工旅游"
                }, {
                    "company": "猿辅导",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/d6f0653b1a4d44740XB_29W0.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/c12d3cf80f72588a1nZ83NS8EFZX.html",
                    "title": "人工智能课程讲师",
                    "jobarea": "北京·朝阳区·望京",
                    "red": "15-30K·13薪",
                    "joblimit": "1-3年硕士",
                    "info": "黎女士HRBP主管",
                    "scale": "在线教育D轮及以上1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100012\/",
                    "falselink": "在线教育",
                    "keywords": "深度学习算法\n                                                    Python\n                                                    人工智能\n                                                    数据结构\n                                                    算法",
                    "tags": "深度学习算法",
                    "description": "餐补，节日福利，定期体检，五险一金，零食下午茶，带薪年假，股票期权，年终奖，补充医疗保险，五一9天假"
                }, {
                    "company": "BOE",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/5894f0f90b9359c51nx_3964.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/c2f9f6f9b8d03f221nZ70tm-EFNT.html",
                    "title": "算法与人工智能工程师",
                    "jobarea": "北京",
                    "red": "15-25K",
                    "joblimit": "在校\/应届硕士",
                    "info": "余女士招聘经理",
                    "scale": "计算机软件已上市10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "视觉图像算法\n                                                    深度学习算法\n                                                    自然语言处理\n                                                    算法设计\n                                                    大数据",
                    "tags": "视觉图像算法",
                    "description": "年终奖，加班补助，五险一金，节日福利，全勤奖，餐补，包吃，带薪年假"
                }, {
                    "company": "网易",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/38bd5c757efa4ab6331z.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/ff0fb8ad15d74aa61nRz39i7GFpY.html",
                    "title": "资深java开发工程师(人工智能-图像)",
                    "jobarea": "北京·海淀区·上地",
                    "red": "25-40K·14薪",
                    "joblimit": "3-5年本科",
                    "info": "邢先生招聘专家",
                    "scale": "移动互联网已上市10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100019\/",
                    "falselink": "移动互联网",
                    "keywords": "Java",
                    "tags": "Java",
                    "description": "全勤奖，五险一金，补充医疗保险，带薪年假，包吃，节日福利，年终奖，免费班车，定期体检"
                }, {
                    "company": "中国电信集成",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/6d830e1ad319b17b1nxz39u1.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/e99d7769885728521nZ63ty4GVpU.html",
                    "title": "人工智能CTO-AI总监【电信集团总部AI中心】",
                    "jobarea": "北京·西城区·新街口",
                    "red": "100-190K",
                    "joblimit": "5-10年本科",
                    "info": "孔先生HR经理",
                    "scale": "通信\/网络设备不需要融资10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100024\/",
                    "falselink": "通信\/网络设备",
                    "keywords": "视觉图像算法\n                                                    深度学习算法\n                                                    自然语言处理\n                                                    算法设计\n                                                    机器学习算法",
                    "tags": "视觉图像算法",
                    "description": "节日福利，年终奖，交通补助，专业培训资源，通讯补贴，餐补，5G内部套餐，培训资源丰富，各类礼品卡，精美自助餐，带薪年假，定期体检，住房补贴，包吃，五险一金，节日、生日卡，保密津贴，补充医疗保险，加班补助，员工旅游，交通补助"
                }, {
                    "company": "先河环保",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/6d47e7a563b449981nR52t65EA~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/75aaf00f7288688f3nd53Ny0GFE~.html",
                    "title": "人工智能算法工程师",
                    "jobarea": "北京·海淀区·清河",
                    "red": "20-35K",
                    "joblimit": "经验不限硕士",
                    "info": "马女士招聘经理",
                    "scale": "数据服务已上市1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100005\/",
                    "falselink": "数据服务",
                    "keywords": "深度学习算法\n                                                    强化学习\n                                                    人工智能\n                                                    物联网",
                    "tags": "深度学习算法",
                    "description": "股票期权，节日福利，带薪年假，免费班车，全勤奖"
                }, {
                    "company": "中科院自动化所",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/83166c289d49672c1HN_3t28.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/d63122ecd62317661nV43dS1EVNV.html",
                    "title": "无人机人工智能助理研究员",
                    "jobarea": "北京·海淀区·中关村",
                    "red": "12-15K",
                    "joblimit": "在校\/应届硕士",
                    "info": "高先生副研究员",
                    "scale": "其他行业不需要融资1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i101304\/",
                    "falselink": "其他行业",
                    "keywords": "人工智能\n                                                    无人机",
                    "tags": "人工智能",
                    "description": "带薪年假，全勤奖，五险一金，年终奖，通讯补贴，加班补助，定期体检，补充医疗保险，交通补助，节日福利，餐补"
                }
            ]
        },
        {
            id: 3,
            data: [
                {
                    "company": "北京智芯微电子",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/8f4eb7d7da908fc81nF839W1Eg~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/e14a73c45e3399b71nV63tm6ElZV.html",
                    "title": "人工智能产品经理",
                    "jobarea": "北京·昌平区·南邵",
                    "red": "15-30K·15薪",
                    "joblimit": "1-3年硕士",
                    "info": "聂先生人工智能组",
                    "scale": "电力\/热力\/燃气\/水利不需要融资1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i101205\/",
                    "falselink": "电力\/热力\/燃气\/水利",
                    "keywords": "产品设计\n                                                    需求分析\n                                                    市场调研",
                    "tags": "产品设计",
                    "description": "年终奖，交通补助，免费班车，带薪年假，餐补，定期体检，补充医疗保险，节日福利，通讯补贴，五险一金"
                }, {
                    "company": "国双",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/d7c1ce471ff642ba1XNz2NS-.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/0d1610a64ad15fed1nVz0tS8F1pS.html",
                    "title": "人工智能产品经理",
                    "jobarea": "北京·海淀区·五道口",
                    "red": "15-25K·14薪",
                    "joblimit": "3-5年本科",
                    "info": "李女士hr",
                    "scale": "互联网已上市1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                    "falselink": "互联网",
                    "keywords": "产品设计\n                                                    人工智能产品\n                                                    人工智能",
                    "tags": "产品设计",
                    "description": ""
                }, {
                    "company": "IBM",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/e96559a653ad69491nR63dg~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/335a639c1e07c87a1nZz2Nm4EVVY.html",
                    "title": "人工智能AI工程师",
                    "jobarea": "北京·海淀区·上地",
                    "red": "18-26K",
                    "joblimit": "1-3年本科",
                    "info": "施女士招聘经理",
                    "scale": "互联网已上市10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                    "falselink": "互联网",
                    "keywords": "深度学习算法\n                                                    自然语言处理\n                                                    视觉图像算法\n                                                    Python\n                                                    人工智能",
                    "tags": "深度学习算法",
                    "description": "补充医疗保险，带薪年假，定期体检，免费班车，加班补助，节日礼物，节日福利，五险一金，年终奖"
                }, {
                    "company": "华为技术有限公司",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/21124e51a5ea83a80nR80ti8.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/95679fa018171ed533N72Ni8E1s~.html",
                    "title": "人工智能软件工程师",
                    "jobarea": "北京·海淀区·西北旺",
                    "red": "30-50K",
                    "joblimit": "经验不限硕士",
                    "info": "王先生开发工程师",
                    "scale": "计算机软件不需要融资10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "人工智能\n                                                    架构师\n                                                    云计算\n                                                    Go\n                                                    Java",
                    "tags": "人工智能",
                    "description": "年终奖，免费班车，离职补偿，员工旅游，定期体检，餐补，加班补助，节日福利，交通补助，五险一金，补充医疗保险，带薪年假"
                }, {
                    "company": "中关村科金",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/2f5d7dc19b8510ad1XV92N-4FA~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/ddb2cb03310e945a1nVy39y7F1BQ.html",
                    "title": "Java架构师（人工智能）",
                    "jobarea": "北京·海淀区·五道口",
                    "red": "25-35K",
                    "joblimit": "5-10年本科",
                    "info": "陈女士招聘主管",
                    "scale": "互联网C轮10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100020\/",
                    "falselink": "互联网",
                    "keywords": "Java\n                                                    Python\n                                                    分布式技术\n                                                    人工智能\n                                                    RPA",
                    "tags": "Java",
                    "description": "带薪年假，年终奖，五险一金，餐补，节日福利"
                }, {
                    "company": "银河证券",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/8f65b22e28de3fb51XR72Nm0.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/6ddc46738bbc952f1nV_0tq8FVpV.html",
                    "title": "人工智能算法工程师",
                    "jobarea": "北京·丰台区·丽泽",
                    "red": "25-50K",
                    "joblimit": "3-5年硕士",
                    "info": "殷先生金融工程研发团队总监",
                    "scale": "互联网金融已上市1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100206\/",
                    "falselink": "互联网金融",
                    "keywords": "深度学习算法\n                                                    金融\n                                                    证券\n                                                    资产管理\n                                                    投资",
                    "tags": "深度学习算法",
                    "description": "定期体检，补充医疗保险，员工旅游，五险一金，年终奖，带薪年假"
                }, {
                    "company": "科电亿网",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/db328aba8f346b0d0n153t-8Fg~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/a8eab9f820462ae31nR73NW4EVRU.html",
                    "title": "人工智能（图像识别方向）工程师",
                    "jobarea": "北京·顺义区·后沙峪",
                    "red": "20-40K",
                    "joblimit": "1-3年硕士",
                    "info": "钟女士HRM",
                    "scale": "计算机服务未融资20-99人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100023\/",
                    "falselink": "计算机服务",
                    "keywords": "人工智能\n                                                    Python\n                                                    C++\n                                                    神经网络\n                                                    TensorFlow",
                    "tags": "人工智能",
                    "description": "节日福利，补充医疗保险，五险一金，餐补，年终奖，带薪年假，定期体检"
                }, {
                    "company": "亚信科技",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/d063e597d01c108b33Z50g~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/3ccd40c2da3f03bd1nV53tq4FlZT.html",
                    "title": "数据科学家-人工智能\/机器学习\/算法工程师",
                    "jobarea": "北京·海淀区·上地",
                    "red": "12-20K·13薪",
                    "joblimit": "在校\/应届博士",
                    "info": "郝女士校园招聘经理",
                    "scale": "计算机软件已上市10000人以上",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "深度学习算法\n                                                    视觉图像算法\n                                                    推荐算法\n                                                    语音算法\n                                                    Python",
                    "tags": "深度学习算法",
                    "description": "带薪年假，五险一金，免费班车，定期体检，节日福利，年终奖，通讯补贴，补充医疗保险"
                }, {
                    "company": "北大青鸟航天桥校区",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/65942b96c269886a03J72Nm7.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/0e54ff64843c91401nV63t-8EVZT.html",
                    "title": "AI人工智能讲师",
                    "jobarea": "北京·海淀区·航天桥",
                    "red": "15-30K",
                    "joblimit": "1-3年大专",
                    "info": "赵先生HR",
                    "scale": "在线教育不需要融资100-499人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100012\/",
                    "falselink": "在线教育",
                    "keywords": "人工智能\n                                                    大数据",
                    "tags": "人工智能",
                    "description": "补充医疗保险，股票期权，带薪年假，通讯补贴，餐补，员工旅游，节日福利"
                }
            ]
        },
        {
            id: 4,
            data: [
                {
                    "company": "中船系统院",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/90efe400f9e23fdd03V_39S1Ew~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/0bfba11036f18b963nB939W5EVU~.html",
                    "title": "研发工程师（人工智能）(J11184)",
                    "jobarea": "北京·海淀区·西北旺",
                    "red": "10-15K",
                    "joblimit": "在校\/应届硕士",
                    "info": "朱女士招聘主管",
                    "scale": "学术\/科研不需要融资1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100304\/",
                    "falselink": "学术\/科研",
                    "keywords": "深度学习算法\n                                                    人工智能",
                    "tags": "深度学习算法",
                    "description": "免费班车，补充医疗保险，节日福利，包吃，带薪年假，五险一金，零食下午茶，定期体检，年终奖，通讯补贴，交通补助"
                }, {
                    "company": "经纬恒润",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/1e8235088d0f8c791nZ9292_GVE~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/685a05ea01fefdd00nV_3Ny4EFI~.html",
                    "title": "人工智能算法工程师",
                    "jobarea": "北京·海淀区·知春路",
                    "red": "25-50K·16薪",
                    "joblimit": "经验不限博士",
                    "info": "薛先生总经理 智能驾驶事业部",
                    "scale": "电子\/半导体\/集成电路未融资1000-9999人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100026\/",
                    "falselink": "电子\/半导体\/集成电路",
                    "keywords": "人工智能\n                                                    机器学习\n                                                    深度学习",
                    "tags": "人工智能",
                    "description": "通讯补贴，节日福利，五险一金，员工旅游，餐补，年终奖，定期体检，带薪年假"
                }, {
                    "company": "领为军融",
                    "company_link": "https:\/\/www.zhipin.com\/gongsi\/18f7883ce9655c5a0nx50926Ew~~.html",
                    "title_link": "https:\/\/www.zhipin.com\/job_detail\/61e8df3c0c930ebd1nR83d26F1RW.html",
                    "title": "人工智能算法工程师",
                    "jobarea": "北京·大兴区·黄村",
                    "red": "30-60K·14薪",
                    "joblimit": "经验不限本科",
                    "info": "潘女士人力资源主管",
                    "scale": "计算机软件未融资20-99人",
                    "falselink_link": "https:\/\/www.zhipin.com\/i100021\/",
                    "falselink": "计算机软件",
                    "keywords": "深度学习算法\n                                                    图像识别\n                                                    语音识别\n                                                    强化学习\n                                                    TensorFlow",
                    "tags": "深度学习算法",
                    "description": "交通补助，餐补，员工旅游，加班补助，定期体检，带薪年假，节日福利，零食下午茶，五险一金，年终奖，股票期权"
                }
            ]
        }
    ]
}