var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-detail" },
    [
      _c("div", { staticClass: "title-wrap" }, [
        _c(
          "h3",
          {
            staticClass: "title",
            style: { borderColor: _vm.$store.state.themeColor },
            on: { click: _vm.goOrders },
          },
          [_vm._v("订单列表")]
        ),
        _c("span", [_vm._v("/")]),
        _c("h3", [_vm._v("订单详情")]),
      ]),
      _vm._l(_vm.order.items, function (item, index) {
        return _c("div", { key: index, staticClass: "course" }, [
          _vm._m(0, true),
          _c("div", { staticClass: "product" }, [
            _c("div", { staticClass: "p-l" }, [
              item.course.is_show == 2
                ? _c(
                    "span",
                    { staticClass: "status-icon status-delete-icon" },
                    [_vm._v("已删除")]
                  )
                : _vm._e(),
              item.course.is_show == 0
                ? _c(
                    "span",
                    { staticClass: "status-icon status-shelves-down-icon" },
                    [_vm._v("已下架")]
                  )
                : _vm._e(),
              _c("img", { attrs: { src: item.course.picture, alt: "" } }),
              _c("div", { staticClass: "pro-tit-wrap" }, [
                _c("p", { staticClass: "pro-tit" }, [
                  _vm._v(_vm._s(item.course.title)),
                ]),
                _c("p", [_vm._v(_vm._s(item.course.period) + "学时")]),
              ]),
            ]),
            _c("div", { staticClass: "price" }, [
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(_vm._s(_vm.order.pay_amount > 0 ? "￥" : "")),
              ]),
              _vm._v(
                _vm._s(_vm.order.pay_amount > 0 ? _vm.order.pay_amount : "免费")
              ),
            ]),
          ]),
        ])
      }),
      _c("div", { staticClass: "item" }, [
        _c("div", {}, [
          _vm._m(1),
          _c("div", {}, [
            _c("span", [_vm._v("订单状态：")]),
            _c("span", [_vm._v(_vm._s(_vm.orderStatus(_vm.order.status)))]),
          ]),
          _c("div", {}, [
            _c("span", [_vm._v("订单编号：")]),
            _c("span", [_vm._v(_vm._s(_vm.order.sn))]),
          ]),
          _c("div", {}, [
            _c("span", [_vm._v("下单时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.order.created_time))]),
          ]),
        ]),
        _c("div", {}, [
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "space-between",
                "padding-right": "20px",
              },
            },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("支付信息"),
              ]),
              _vm.order.refund_status == 1
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#0071F5", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.refundDoing(_vm.order.status, _vm.order.sn)
                        },
                      },
                    },
                    [_vm._v("申请退款")]
                  )
                : _vm.order.refund_status == 3
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#0071F5", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.refundDoing(_vm.order.status, _vm.order.sn)
                        },
                      },
                    },
                    [_vm._v("退款明细")]
                  )
                : _c("span"),
            ]
          ),
          _c("div", {}, [
            _c("span", [_vm._v("支付状态：")]),
            _c("span", [_vm._v(_vm._s(_vm.orderStatus(_vm.order.status)))]),
          ]),
          _c("div", {}, [
            _c("span", [_vm._v("支付方式：")]),
            _c("span", [_vm._v(_vm._s(_vm.payMentTxt(_vm.order.payment)))]),
          ]),
          _c("div", {}, [
            _c("span", [_vm._v("支付时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.order.pay_time))]),
          ]),
        ]),
      ]),
      _vm.order.pay_amount > 0 && _vm.$store.state.open_wechat_pay == 0
        ? _c("div", { staticClass: "item", staticStyle: { height: "228px" } }, [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticStyle: {
                    "justify-content": "space-between",
                    "padding-right": "20px",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("开票信息"),
                  ]),
                  _vm.order.invoice_status == 1
                    ? _c(
                        "span",
                        {
                          staticStyle: { color: "#0071F5", cursor: "pointer" },
                          on: { click: _vm.takeInvoice },
                        },
                        [_vm._v("开发票")]
                      )
                    : _vm._e(),
                  _vm.order.invoice_status == 3
                    ? _c(
                        "span",
                        {
                          staticStyle: { color: "#0071F5", cursor: "pointer" },
                          on: { click: _vm.takeInvoice },
                        },
                        [_vm._v("查看发票")]
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", {}, [
                _c("span", [_vm._v("开票状态：")]),
                _c("div", { staticStyle: { "margin-left": "20px" } }, [
                  !_vm.invoice
                    ? _c("span", [_vm._v("--")])
                    : _vm.invoice && _vm.invoice.status == "sent"
                    ? _c("span", [_vm._v("已开票")])
                    : _vm.invoice && _vm.invoice.status == "doing"
                    ? _c("span", [_vm._v("开票中")])
                    : _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("开票失败"),
                      ]),
                ]),
              ]),
              _vm._m(2),
              _c("div", {}, [
                _c("span", [_vm._v("发票抬头：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.invoice && _vm.invoice.title
                        ? _vm.invoice && _vm.invoice.title
                        : "--"
                    )
                  ),
                ]),
              ]),
              _c("div", {}, [
                _c("span", [_vm._v("发票内容：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.invoice && _vm.invoice.content
                        ? _vm.invoice && _vm.invoice.content
                        : "--"
                    )
                  ),
                ]),
              ]),
              _c("div", {}, [
                _c("span", [_vm._v("开票时间：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.invoice && _vm.invoice.created_time
                        ? _vm.invoice && _vm.invoice.created_time
                        : "--"
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div", {}),
          ])
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            width: "500px",
            zIndex: 1000,
            footer: "",
            title: "申请退款",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "tag" }, [
              _c("img", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  src: require("../../assets/images/warn.png"),
                  alt: "",
                },
              }),
              _c("p", [
                _vm._v(
                  "退款需满足课程内任何一个课时没有学习进度，才可以申请退款。"
                ),
              ]),
            ]),
          ]),
          _vm.order.status == "paid" || _vm.order.status == "finished"
            ? _c("div", { staticClass: "txt-info" }, [
                _c(
                  "div",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                      _vm._v("退款原因"),
                    ]),
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { "default-value": "1" },
                        on: { change: _vm.handleChange },
                      },
                      [
                        _c("a-select-option", { attrs: { value: "1" } }, [
                          _vm._v("买错课了"),
                        ]),
                        _c("a-select-option", { attrs: { value: "2" } }, [
                          _vm._v("不想学了"),
                        ]),
                        _c("a-select-option", { attrs: { value: "3" } }, [
                          _vm._v("其他"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("退款备注")]),
                    _c("a-textarea", {
                      attrs: { placeholder: "请输入...", rows: 4 },
                      model: {
                        value: _vm.msg,
                        callback: function ($$v) {
                          _vm.msg = $$v
                        },
                        expression: "msg",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "refundTxt" }, [
            _c("div", {}, [
              _c("span", [_vm._v("退款规则：")]),
              _c("span", [_vm._v("按照原路返回支付账户")]),
            ]),
            _c("div", {}, [
              _c("span", [_vm._v("退款时间：")]),
              _c("span", [_vm._v("一般为3个工作日左右")]),
            ]),
          ]),
          _c(
            "div",
            {},
            [
              (_vm.order.status == "refunded" ||
                _vm.order.status == "refunding") &&
              _vm.$store.state.open_wechat_pay == 0
                ? _c("p", [_vm._v("开票信息")])
                : _vm._e(),
              _c(
                "a-timeline",
                _vm._l(_vm.refund_flow, function (item, index) {
                  return _c(
                    "a-timeline-item",
                    { key: index },
                    [
                      _vm._l(item.text, function (items, i) {
                        return _c("p", { key: i }, [
                          _vm._v(_vm._s(items.value)),
                        ])
                      }),
                      _c("p", [_vm._v(_vm._s(item.deal_time))]),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "refund-btn" },
            [
              _vm.order.status == "paid" || _vm.order.status == "finished"
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refundding(_vm.order.sn)
                        },
                      },
                    },
                    [_vm._v("申请退款")]
                  )
                : _vm._e(),
              _vm.order.status == "refunded" || _vm.order.status == "refunding"
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.ok(_vm.order.sn)
                        },
                      },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.$store.state.open_wechat_pay == 0
        ? _c(
            "a-modal",
            {
              attrs: {
                width: "500px",
                footer: "",
                title:
                  !_vm.invoice ||
                  (_vm.invoice && _vm.invoice.status == "refused")
                    ? "开发票"
                    : "查看发票",
              },
              model: {
                value: _vm.visible2,
                callback: function ($$v) {
                  _vm.visible2 = $$v
                },
                expression: "visible2",
              },
            },
            [
              !_vm.invoice || (_vm.invoice && _vm.invoice.status == "refused")
                ? _c("div", { staticClass: "modal-top" }, [
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("发票类型"),
                          ]),
                          _c(
                            "a-radio-group",
                            [_c("a-radio", [_vm._v(" 电子普通发票 ")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("发票抬头"),
                          ]),
                          _c(
                            "a-radio-group",
                            {
                              on: { change: _vm.onChange },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v(" 个人 "),
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v(" 单位 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.value == 0
                      ? _c("div", { staticClass: "input-item" }, [
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("发票内容"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入个人或您的姓名" },
                                model: {
                                  value: _vm.buyer_name,
                                  callback: function ($$v) {
                                    _vm.buyer_name = $$v
                                  },
                                  expression: "buyer_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.value == 1
                      ? _c("div", { staticClass: "input-item" }, [
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("企业名称"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入企业名称" },
                                model: {
                                  value: _vm.buyer_name,
                                  callback: function ($$v) {
                                    _vm.buyer_name = $$v
                                  },
                                  expression: "buyer_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("企业税号"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入企业税号" },
                                model: {
                                  value: _vm.tax_num,
                                  callback: function ($$v) {
                                    _vm.tax_num = $$v
                                  },
                                  expression: "tax_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("手机号"),
                          ]),
                          _c("a-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.mobile,
                              callback: function ($$v) {
                                _vm.mobile = $$v
                              },
                              expression: "mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("收票邮箱"),
                          ]),
                          _c("a-input", {
                            attrs: { placeholder: "请输入接收发票的邮箱" },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.invoice || (_vm.invoice && _vm.invoice.status == "refused")
                ? _c("div", { staticClass: "modal-bot" }, [
                    _c("p", { staticClass: "invoice-title" }, [
                      _vm._v("开具发票规则"),
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("p", [
                        _vm._v(
                          "1. 根据国家税务规定，不能向个人开具增值税专用发票"
                        ),
                      ]),
                      _c("p", [_vm._v("2. 发票金额不含优惠券支付部分")]),
                      _c("p", [
                        _vm._v("3. 不同开票主体的发票，需要分别提交开票申请"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "4. 电子普通发票是税局认可的有效付款凭证，其法律效力、基本用途及使用规定同纸质发票"
                        ),
                      ]),
                      _c("p", [_vm._v("5. 请您按照税法规定使用发票")]),
                      _c("p", [
                        _vm._v("6. 有任何疑问，请您及时咨询我们的客服"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              !_vm.invoice || (_vm.invoice && _vm.invoice.status == "refused")
                ? _c(
                    "div",
                    { staticClass: "invoice-btn" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.startInvoice },
                        },
                        [_vm._v("立即开票")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.invoice && _vm.invoice && _vm.invoice.status == "doing") ||
              (_vm.invoice && _vm.invoice.status == "sent")
                ? _c("div", { staticClass: "see-invoice" }, [
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("发票类型")]),
                      _c("p", [_vm._v("电子普通发票")]),
                    ]),
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("发票抬头")]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.title))]),
                    ]),
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("收票邮箱")]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.email))]),
                    ]),
                  ])
                : _vm._e(),
              (_vm.invoice && _vm.invoice.status == "doing") ||
              (_vm.invoice && _vm.invoice.status == "sent")
                ? _c("div", { staticClass: "invoice-img" }, [
                    _c("div", { staticClass: "invoice-img-title" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("发票"),
                      ]),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#0071F5", cursor: "pointer" },
                          attrs: {
                            href: _vm.invoice.img,
                            target: "_blank",
                            download: "",
                          },
                        },
                        [_vm._v("下载发票")]
                      ),
                    ]),
                    _c("img", { attrs: { src: _vm.invoice.img, alt: "" } }),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "invoice-btn" },
                [
                  (_vm.invoice && _vm.invoice.status == "doing") ||
                  (_vm.invoice && _vm.invoice.status == "sent")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.invoiceOk },
                        },
                        [_vm._v("确定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top" }, [
      _c("span", { staticStyle: { flex: "1", "font-weight": "bold" } }, [
        _vm._v("课程信息"),
      ]),
      _c("span", { staticStyle: { flex: "1", "font-weight": "bold" } }, [
        _vm._v("实付金额"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("订单信息"),
      ]),
      _c("span"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", [_vm._v("发票类型：")]),
      _c("span", [_vm._v("电子普通发票")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }