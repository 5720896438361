<template>
    <div class="studydata">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>学习资料</h3>
        </div>
        <div class="data-download">
            <div class="empty" v-if="wares.length == 0">
                <img src="../../assets/images/weipingjia.png" alt="">
                <span>暂无资料提供下载~</span>
            </div>
            <ul>
            <li class="download-item" v-for="(item, i) in wares" :key="i">
                <div class="download-item-left">
                <img :src="require('../../assets/images/'+item.file_uri.split('.')[3]+'.png')" alt="">
                <span>{{item.title}}</span>
                </div>

                <div class="download-item-right">
                <span>{{item.download_times}}&nbsp;人已下载</span>
                <div @click="downloadMethod(item)">
                    <a :href="item.file_uri" download>点击下载</a>
                </div>

                </div>
            </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {
  download
} from '../../http/api'
export default {
    props: {
        wares: Array
    },
    methods: {
        downloadMethod(item) {
            download({
                course_id: item.course_id,
                course_type: '',
                ware_id: item.id
            }).then(res => {

            })
      },
    }
}
</script>

<style scoped>
.download-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #A6B7BF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.download-item img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.download-item-left, .download-item-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.download-item-left span {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.download-item-right span {
  font-size: 12px;
  color: #666;
  margin-right: 20px;
}
.download-item-right a {
  width: 80px;
  display: block;
  height: 24px;
  line-height: 24px;
  background: #0071F5;
  color: #fff;
  font-size: 12px;
  padding: 0;
  border-radius: 12px;
  border: none;
  background-image: url('../../assets/images/download.png');
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 16px 16px;
}
.empty {
  padding: 50px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
</style>