<template>
    <div class="code">
        <div class="bg">
            <img style="object-fit: cover;" src="https://bjadks-assets.oss-cn-beijing-internal.aliyuncs.com/assets/eduvue/images/codeBg.jpg" alt="">
            <img class="codes" :src="$store.state.mini_program_qrcode" alt="">
        </div>


    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .bg {
        width: 100%;
        position: relative;
        height: 100%;
    }
    .codes {
        width:180px;
        height: 180px;
        position: absolute;
        left: 20%;
        top: 50%;
        border-radius: 100px;
    }
    .code {
        display: flex;
        align-items: center;
    }
</style>
