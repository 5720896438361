var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "studydata" }, [
    _vm._m(0),
    _c("div", { staticClass: "data-download" }, [
      _vm.wares.length == 0
        ? _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/weipingjia.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("暂无资料提供下载~")]),
          ])
        : _vm._e(),
      _c(
        "ul",
        _vm._l(_vm.wares, function (item, i) {
          return _c("li", { key: i, staticClass: "download-item" }, [
            _c("div", { staticClass: "download-item-left" }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/" +
                    item.file_uri.split(".")[3] +
                    ".png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v(_vm._s(item.title))]),
            ]),
            _c("div", { staticClass: "download-item-right" }, [
              _c("span", [_vm._v(_vm._s(item.download_times) + " 人已下载")]),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.downloadMethod(item)
                    },
                  },
                },
                [
                  _c("a", { attrs: { href: item.file_uri, download: "" } }, [
                    _vm._v("点击下载"),
                  ]),
                ]
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-class-all" }, [
      _c("span", { staticClass: "icon" }),
      _c("h3", [_vm._v("学习资料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }