<template>
    <div class="testify">
        <h3 class="title" :style="{borderColor: $store.state.themeColor}">学习证明</h3>
        <div class="loadding" v-show="!show">
            <a-spin />
        </div>
        <transition name="fades">
            <div v-if="show">
                <table id="printMe">
                    <tbody>
                        <tr>
                            <td>姓名</td>
                            <td>{{res.user.realname}}</td>
                            <td>出生年月</td>
                            <td>{{res.user.birth_day}}</td>
                        </tr>
                        <tr>
                            <td>姓别</td>
                            <td>{{res.user.gender == 0? '男': '女'}}</td>
                            <td>身份证号</td>
                            <td>{{res.user.idcard}}</td>
                        </tr>
                        <tr>
                            <td>电话</td>
                            <td>{{res.user.mobile}}</td>
                            <td>所在公司</td>
                            <td>{{res.user.company}}</td>
                        </tr>
                        <tr>
                            <td colspan=4>学习情况</td>
                        </tr>
                        <tr>
                            <td colspan=4>
                                <ul class="line">
                                    <li>
                                        <p class="fw">课程</p>
                                        <p class="fw">学习时间</p>
                                        <p class="fw">完成度</p>
                                    </li>
                                    <li v-for="(item, index) in res.course_member" :key="index">
                                        <p>{{item.title}}</p>
                                        <p>{{item.learn_time}}</p>
                                        <p>{{item.learn_percent}}%</p>
                                    </li>
                                </ul>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <a-button v-print="printObj" type="primary" size="large">打印</a-button>
            </div>
        </transition>
    </div>
</template>

<script>
import {
    getRcord
} from '../../http/api'

export default {
    data() {
        return {
            res: '',
            show: false,
            printObj: {
              id: "printMe",
              popTitle: '',
              extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            }
        }
    },
    created() {
        this.show = false
        getRcord().then(res => {
            this.show = true
            if(res.data.code == 200) {
                this.res = res.data.data
                if(this.res.user.company)
                this.printObj.popTitle = res.user.company
            }
        })
    }
}
</script>

<style scoped>
.testify {
    padding: 20px;
    border: 1px solid #eee;
}
.loadding {
  height: 500px;
}
.title {
  border-left: 4px solid #fff;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}
table {
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
}
table td {
    border: 1px solid #999;
    height: 44px;
}
table .line li{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
table .line li p {
    flex: 1;
    text-align: left;
    padding-left: 50px;
    margin: 0;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.fw {
    font-weight: bold;
}
</style>
