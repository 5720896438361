var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help" }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _vm.$store.state.template == 1
          ? _c("List", { attrs: { type: "help", cate: 2 } })
          : _vm._e(),
        _vm.$store.state.template == 2
          ? _c("ListTwo", { attrs: { type: "help", cate: 2 } })
          : _vm._e(),
        _vm.$store.state.template == 3
          ? _c("ListThr", { attrs: { type: "help", cate: _vm.cate } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }