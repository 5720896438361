var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "code" }, [
    _c("div", { staticClass: "bg" }, [
      _c("img", {
        staticStyle: { "object-fit": "cover" },
        attrs: {
          src: "https://bjadks-assets.oss-cn-beijing-internal.aliyuncs.com/assets/eduvue/images/codeBg.jpg",
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "codes",
        attrs: { src: _vm.$store.state.mini_program_qrcode, alt: "" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }